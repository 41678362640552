<template>
  <div>
    <div :class="[$style.title, 'login-heading']">
      <h1>
        <strong>パスワード設定</strong>
      </h1>
    </div>
    <div :class="$style.block">
      <div class="row">
        <div class="col-xl-12">
          <div :class="$style.inner">
            <div :class="$style.form">
              <a-form-model
                :model="form"
                ref="ruleForm"
                class="login-form"
                :rules="rules"
              >
                <a-form-model-item ref="password" label="パスワード" prop="password">
                  <a-input-password
                    v-model="form.password"
                    placeholder="設定したいパスワードを8文字以上で入力してください。"
                    type="password"
                    @blur="
                      () => {
                        $refs.password.onFieldBlur()
                      }
                    "
                  >
                    <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25);"/>
                  </a-input-password>
                </a-form-model-item>

                <a-form-model-item ref="password_confirmation" label="パスワード（再入力）" prop="password_confirmation">
                  <a-input-password
                    v-model="form.password_confirmation"
                    placeholder="パスワードを再度入力してください。"
                    type="password"
                    @blur="
                      () => {
                        $refs.password_confirmation.onFieldBlur()
                      }
                    "
                  >
                    <a-icon slot="prefix" type="exclamation-circle" style="color: rgba(0,0,0,.25);"/>
                  </a-input-password>
                </a-form-model-item>

                <div class="form-actions">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    class="login-form-button width-150"
                    @click="handleSubmit"
                    :loading="loading"
                  >設定</a-button>
                </div>
              </a-form-model>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import router from '@/router'
import store from '@/store'

export default {
  data: function () {
    return {
      loading: false,
      form: {
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed: {
    rules: function() {
      return {
        password: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { min: 8, message: '8文字以上で入力してください。', trigger: 'blur' },
        ],
        password_confirmation: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { validator: this.passwordConfirmationRule, message: 'パスワードと同じ文字列を入力してください。', trigger: 'blur' },
        ],
      }
    },
  },
  methods: {
    passwordConfirmationRule() {
      if (this.form.password === this.form.password_confirmation) return true
      return false
    },
    handleSubmit(e) {
      e.preventDefault()
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          _this.loading = true
          this.$nprogress.start()
          const send = Vue.prototype.$api.send('post', 'auth/confirm', {
            confirmation_token: this.$route.params.confirmation_token,
            password: this.form.password,
          })
          send.then(response => {
            localStorage.removeItem('app.user.accessToken')
            localStorage.removeItem('app.user.refreshToken')
            localStorage.removeItem('app.user.lastGetAccessToken')
            store.commit('INITIALIZE_USER')
            this.$notification['success']({
              message: 'パスワードを設定しました。再度ログインしてください。',
            })
            router.push('/')
            _this.loading = false
          })
            .catch(error => {
              _this.$notification['error']({
                message: error.status + ': ' + error.data.data,
              })
              _this.loading = false
              console.log(error)
            })
        } else {
          this.$notification['error']({
            message: '入力に正しくない箇所があります。',
          })
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
