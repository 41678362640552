var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: [_vm.$style.title, "login-heading"] }, [_vm._m(0)]),
    _c("div", { class: _vm.$style.block }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12" }, [
          _c("div", { class: _vm.$style.inner }, [
            _c(
              "div",
              { class: _vm.$style.form },
              [
                _c(
                  "a-form-model",
                  {
                    ref: "ruleForm",
                    staticClass: "login-form",
                    attrs: { model: _vm.form, rules: _vm.rules }
                  },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        ref: "password",
                        attrs: { label: "パスワード", prop: "password" }
                      },
                      [
                        _c(
                          "a-input-password",
                          {
                            attrs: {
                              placeholder:
                                "設定したいパスワードを8文字以上で入力してください。",
                              type: "password"
                            },
                            on: {
                              blur: function() {
                                _vm.$refs.password.onFieldBlur()
                              }
                            },
                            model: {
                              value: _vm.form.password,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password"
                            }
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { color: "rgba(0,0,0,.25)" },
                              attrs: { slot: "prefix", type: "lock" },
                              slot: "prefix"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "password_confirmation",
                        attrs: {
                          label: "パスワード（再入力）",
                          prop: "password_confirmation"
                        }
                      },
                      [
                        _c(
                          "a-input-password",
                          {
                            attrs: {
                              placeholder: "パスワードを再度入力してください。",
                              type: "password"
                            },
                            on: {
                              blur: function() {
                                _vm.$refs.password_confirmation.onFieldBlur()
                              }
                            },
                            model: {
                              value: _vm.form.password_confirmation,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "password_confirmation", $$v)
                              },
                              expression: "form.password_confirmation"
                            }
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { color: "rgba(0,0,0,.25)" },
                              attrs: {
                                slot: "prefix",
                                type: "exclamation-circle"
                              },
                              slot: "prefix"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-actions" },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "login-form-button width-150",
                            attrs: {
                              type: "primary",
                              htmlType: "submit",
                              loading: _vm.loading
                            },
                            on: { click: _vm.handleSubmit }
                          },
                          [_vm._v("設定")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [_c("strong", [_vm._v("パスワード設定")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }